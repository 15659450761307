import React, { useState } from 'react';
import Toolbar from './Toolbar';
import SideDrawer from './sideDrawer';

const Header = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  return (
    <div className="header">
      <Toolbar drawerClickHandler={drawerToggleClickHandler} />
      <SideDrawer
        show={sideDrawerOpen}
        drawerToggleClickHandler={drawerToggleClickHandler}
      />
      {sideDrawerOpen && (
        //eslint-disable-next-line
        <div className="backdrop" onClick={backdropClickHandler}></div>
      )}
    </div>
  );
};

export default Header;
