import React from 'react';
import { Link } from 'gatsby';

const SideDrawer = (props) => {
  let drawerClasses = ['sidedrawer'];
  if (props.show) {
    drawerClasses = ['sidedrawer open'];
  }
  return (
    <nav className={drawerClasses}>
      <ul>
        <Link to="/about" onClick={props.drawerToggleClickHandler}>
          About
        </Link>
        <Link to="/news" onClick={props.drawerToggleClickHandler}>
          News
        </Link>
        <Link to="/showreels" onClick={props.drawerToggleClickHandler}>
          Showreels
        </Link>
        <Link to="/netball" onClick={props.drawerToggleClickHandler}>
          Netball
        </Link>
        <Link to="/credits" onClick={props.drawerToggleClickHandler}>
          Credits
        </Link>
        <Link to="/projects" onClick={props.drawerToggleClickHandler}>
          Projects
        </Link>
        <Link to="/gallery" onClick={props.drawerToggleClickHandler}>
          Gallery
        </Link>
        <Link to="/contact" onClick={props.drawerToggleClickHandler}>
          Contact
        </Link>
      </ul>
    </nav>
  );
};

export default SideDrawer;
