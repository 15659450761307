import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../hooks/use-sitemetadata';
import Header from '../components/header';
import favicon from '../static/favicon.ico';

import SocialIcons from '../components/socialIcons';
import AgentDetailsFooter from '../components/agentDetailsFooter';

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata();

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <main>
        <Header />
        {children}
      </main>
      <SocialIcons />
      <AgentDetailsFooter />
    </>
  );
};

export default Layout;
