import React from 'react';
import { Link } from 'gatsby';
import DrawerToggleButton from './drawerToggle';

const Toolbar = (props) => (
  <header className="Toolbar">
    <nav className="Toolbar-navigation">
      <div className="toolbarbuttontoggle">
        <DrawerToggleButton click={props.drawerClickHandler} />
      </div>
      <div className="Toolbar-logo">
        <Link to="/">
          <strong>LINDSEY CHAPMAN</strong>
        </Link>
      </div>
      <div className="spacer"></div>
      <div className="Toolbar-nav-items">
        <ul>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/news">News</Link>
          </li>
          <li>
            <Link to="/showreels">Showreels</Link>
          </li>
          <li>
            <Link to="/netball">Netball</Link>
          </li>
          <li>
            <Link to="/credits">Credits</Link>
          </li>
          <li>
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/contact" className="contactButton">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
);

export default Toolbar;
