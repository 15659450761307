import React from 'react';
import twitter from '../images/twitter.png';
import instagram from '../images/instagram.png';

const SocialIcons = () => {
  return (
    <footer className="footerWrapper">
      You can follow me here:
      <div className="socialMedia_Wrapper">
        <a
          href={`https://instagram.com/lindseychapmanpresenter`}
          className="instagramLogo__Link"
          target="blank"
        >
          <img
            src={instagram}
            alt="instagram"
            className="instagramLogo__Image"
          />
        </a>
        <a
          href={`https://twitter.com/Lindsey_chapman`}
          className="twitterLogo__Link"
          target="blank"
        >
          <img src={twitter} className="twitterLogo__Image" alt="twitter" />
        </a>
      </div>
    </footer>
  );
};

export default SocialIcons;
