import React from 'react';

const AgentDetailsFooter = () => {
  return (
    <div className="contactFooter" style={{ backgroundColor: '#1f2833' }}>
      <h1> Contact </h1>
      <div>
        <a href="tel:+441264771726">
          David Foster Management
          <br />
          Tel: +44 (0)1264 771726
        </a>
        <br />
        <a href="mailto:david@dfmanagement.tv">Email: david@dfmanagement.tv</a>
        <br />
        <a href="mailto:admin@lindseychapman.co.uk">
          Having site issues? email:admin@lindseychapman.co.uk
        </a>
      </div>
    </div>
  );
};

export default AgentDetailsFooter;
